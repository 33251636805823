import styled from 'styled-components';
import img from '../../assets/images/bharatBanner.png'

export const Wrapper = styled.div`
    background:  #58ACBF;

	margin: -30px;
	padding-left: 47px;
	 height: 356px;
	 overflow: hidden;
	
  &> div{
	background-image: url(${props => props.img});
	background-size: 370px;
	background-position: left bottom ;
	background-repeat: no-repeat;

	.banner-text {
		padding: 76px 40px 20px 0px;
		text-align: end;
		color: #333;

		h1 {
			
			font-size: 36px;
			line-height: 42px;
			font-weight: bold;
			
			text-transform: uppercase;
		}
		.flat-text {
			font-size: 26px;
			line-height: 39px;
			font-weight: 500;
			margin-bottom: 13px;
		}
		i {
			margin-top: 82px;
			display: block;
			font-style: normal;
			font-size: 10px;
			line-height: 16px;
		}
	}
  }

	
`;