import React from 'react';
import { ModalWraper } from '../UI/styledConstants';
import IconClose from '../../assets/images/IconClose';
import logoBlack from '../../assets/images/logo.png';
import backgroundImg from '../../assets/images/bg-img.png';
import { downloadDivAsImage, numberToWords } from '../../utils/common';
const ElectricityReceipt = ({ payDaital, onCloseModal }) => {
	const handleDownloadClick = (title) => {
		const divElement = document.getElementById('model');
		downloadDivAsImage(divElement, title);
	};
	console.log("payDaital",payDaital)
	return (
		<ModalWraper url={backgroundImg}>
			<div className="modal modal-success" id="model">
				<div className="flex modal-header justify-between">
					<div className="logo" >
						<img src={logoBlack} alt={'logo'} style={{height:"50px"}} />
						<div>
							<p>Invoicing and payments</p>
							<p>powered by Laetitia Finserv </p>
						</div>
					</div>

					<div className="buttons">
						<button className="button-solid" style={{ marginRight: '8px' }} onClick={() => handleDownloadClick('send money receipt')}>
							Download
						</button>
						<button className="button-solid" onClick={() => window.print()}>
							Print
						</button>
					</div>
				</div>
				<div className="modal-close" onClick={() => onCloseModal()}>
					<IconClose />
				</div>
				<div className="text-center modal-title">
					<h3 className="amount">₹ {payDaital?.amount}</h3>
					<p className="amount-text">{numberToWords(payDaital?.amount)} rupee only</p>
				</div>
				<div className="modal-body">
					<div className="txn-daital">
						<div className="flex justify-between txn-daital-h">
							{/* <div>Customer Details</div> */}
							<div></div>
						</div>
						<div className="flex justify-between txn-desc">
							<div className="txn-desc-right">
								<div>
									<span className="heading">Status:</span>
									<span>{payDaital?.status}</span>
								</div>
								<div>
									<span className="heading">Service:</span>
									<span>{payDaital?.service}</span>
								</div>
								

								<div>
									<span className="heading">OrderId:</span>
									<span>{payDaital?.orderId}</span>
								</div>
								<div>
									<span className="heading">Charges:</span>
									<span>{payDaital?.charges}</span>
								</div>
								<div>
									<span className="heading">Date: </span>
									<span>{payDaital?.date}</span>
								</div>
							</div>
							<div className="txn-desc-left">
								
								
							</div>
						</div>
					</div>
					{/* <div className="txn-daital">
						<div className="flex justify-between txn-daital-h">
							<div>Payment Details</div>
							<div></div>
						</div>
						<div className="flex justify-between txn-desc">
							<div className="txn-desc-right">
								<div>
									<span className="heading">Bank Name</span>
									<span>{payDaital?.billerDetails?.name}</span>
								</div>
								<div>
									<span className="heading">Account</span>
									<span>{payDaital?.billerDetails?.account}</span>
								</div>
							</div>
							<div className="txn-desc-left"></div>
						</div>
					</div> */}
				</div>
			</div>
		</ModalWraper>
	);
};

export default ElectricityReceipt;
