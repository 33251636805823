import React from 'react';
import './Contactus.css';
import location from '../../assets/images/location.png';
import envelop from '../../assets/images/envelop.png';
import mobilebutton from '../../assets/images/mobilebutton.png';
function Contactus() {
	return (
		<div className="content-wrapper">
			<div className="heading">
				<h2 className="m-auto">Contact Us</h2>
			</div>
			<div className="contactuswrapper">
				<div className="containers">
					<div className="heading-contact text-center">
						<label className="m-auto">
							We’d Love To <strong>Help You</strong>
						</label>
					</div>
					<div className="consumer-top  consumer-middle flex justify-center gap-100">
						<div className="contactus">
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.1217515576586!2d72.83288367424058!3d19.189883882038355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b6c37df6fa09%3A0xace2823d4cc4fcc0!2sNew%20Link%20Rd%2C%20Mumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1724656571224!5m2!1sen!2sin"
								width="100%"
								height="100%"
								allowfullscreen=""
								loading="lazy"
								referrerpolicy="no-referrer-when-downgrade"
								title="map"
							></iframe>
							<div className="contactusdetail">
								<div className="contactaddress">
									<div className="address">
										<label>Registered Address</label>
									</div>
									<div className="address" style={{flexWrap:"wrap"}}>
										<img src={location} alt="location" />
										<p>
										506-B wing, Galaxy Tower Gaondevi, Link Road, Mumbai City, Goregaon West, Maharashtra, India, 400104
										</p>
										<br />
										<p>499 Saheed Nagar, Bhubaneswar, Orissa</p>
									</div>
								</div>
								<div className="contactaddress">
									<div className="address">
										<label>Contact Us</label>
									</div>
									<div className="address">
										<img src={envelop} alt="envelop" />
										<p>
											<strong>Email Id :</strong>founder.blinkpay@gmail.com
										</p>
									</div>
									<div className="address">
										<img src={mobilebutton} alt="mobilebutton" />
										<p>
											<strong>Phone No :</strong>9078311404
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Contactus;
