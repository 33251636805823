import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import playstor from '../../assets/images/playstor.svg';
import applestor from '../../assets/images/apple.svg';

function Component() {
	return (
		<footer>
			<div className="footer container flex justify-between  gap-20">
				<div className="logo">
					<Link to="/dashboard">
						<img src={logo} alt="logo" style={{maxWidth:"120px"}} />
					</Link>
					<p>A simple, secure and convenient way to make Fasttag bill payments</p>
				</div>
				<div className="menus flex grow-1 justify-between gap-50">
					<div className="compny">
						{/* <h4>Our company</h4>
						<div>
							<Link to="/dashboard">Home</Link>
							<Link to="/business">Business</Link>
							<Link to="/consumer">Consumer</Link>
							<Link to="/company">Company</Link>
						</div> */}
					</div>
          <div className="Support">
            <h4>Support & Services</h4>
            <div>
            {/* <Link to="/services">Services</Link> */}
            <Link to="/dashboard">Home</Link>
							<Link to="/business">Business</Link>
							<Link to="/consumer">Consumer</Link>
							<Link to="/company">Company</Link>
              <Link to='/faq'> FAQ </Link>             
              <Link to='/contactus'> Contact Us</Link>
            </div>
          </div>
          {/* <div className="Connect">
            <h4>Connect with us</h4>
            <div className="socal-icons flex">

            <div className='center'><a href="https://www.facebook.com/" target='-blank'><i className="fa-brands fa-facebook"></i></a></div>
            <div className='center'><a href="https://www.linkedin.com/" target='_blank'><i className="fa-brands fa-linkedin"></i></a></div>
            <div className='center'> <a href="https://twitter.com/" target='_blank' ><i class="fa-brands fa-x-twitter"></i></a></div>
            <div className='center'><a href="https://www.instagram.com/" target='_blank'><i className="fa-brands fa-instagram"></i></a></div>
             
            </div>
            
         
          </div> */}

        </div>

      </div>
      <div className=" container footerBottom flex justify-between">
        <div className='footer-botton'>
        Copyright © 2023 Laetitia Finserv All rights reserved.
        </div>
        <div className='footer-botton'>
        <Link to="/privacy-policy">Privacy Policy</Link> | <Link to="/terms-conditions">Terms & conditions</Link> 
        </div>

      </div>
    </footer>
  );
}

export default Component;
