
import payIcons from '../../assets/images/playstor.svg';
import { QeWrapper } from './style';


const QrCode = ({ qrcode, setTimeover, resp }) => {
	return (
		<>
			<QeWrapper className="qrWeapper flex">
				<div className="left-box">
					<div className="img">
						<img src={`data:image/png;base64,${qrcode}`} alt="pay with qr" />
						{/* <Timer setTimeover={setTimeover} /> */}
					</div>
				</div>
				<div className="middle"></div>
				<div className="right-box text-center">
					<h2>Pay with QR</h2>
					<h3>
						Scan QR code to make <br /> a payment.
					</h3>
					<p>Pay with any Payment App</p>

					<img src={payIcons} alt="icons" />
					<a className="button-outline" href={resp?.upiUrl} style={{ padding: '8px 20px', display: 'block', marginInline: 'auto', width: '300px' }}>
						{' '}
						Pay With UPI
					</a>
				</div>
			</QeWrapper>
		</>
	);
};

export default QrCode;