import styled from "styled-components";


export const QeWrapper = styled.div`
justify-content: center;
margin-top: 40px;
.left-box {
   padding: 0 30px 0 10px;
   .img{
   max-width: 280px;
   padding: 10px 10px 20px 10px;
   border-radius: 10px;
   background: #FFFFFF;
box-shadow: 0px 1px 2px #0000000D;
border: 1px solid #EAEAEA;
position: relative;

gap: 10px;
.timer{
   position: absolute;
   bottom: 9px;
   left: 50%;
   font-size: 13px;
   transform: translateX(-50%);
}

img{
   width: 100%;
}
}
}
.middle{
   border-left: 1px dashed #1E437A;
}
.right-box,.left-box{
   max-width: 300px;
   padding: 20px 30px;
   font-family: 'Poppins', sans-serif;
   
   h2{
       font-size: 28px;
       margin-bottom: 10px;
   

   }
   h3{
       font: normal normal medium 20px/30px;
       margin-bottom: 25px;
   }
   p{
       font: normal normal medium 13px/20px;
       margin-bottom: 12px;
   }
   a{
       width: 100%!important;
       margin-top: 20px;
   }
}
.left-box{
   h2,h3{
       display: none;
       text-align: center;
   }
}
@media screen and (max-width: 992px)  {
   flex-direction: column;
   align-items: center;
   .left-box{
   h2,h3{
       display: block;
       
   }

}
.right-box{
   h2,h3{
      display: none;
   }
   p{
       margin-top: 10px;
   }
}
}
`;