import React, { useEffect, useState} from 'react';
import { CustomDropdownWrapper } from './style';
import { isEmpty } from 'lodash';

const CustomDropdown = ({ list, setProvider, setMessage, type ,controls}) => {
	const [searchQuery, setSearchQuery] = useState('');

	const [listShow, setListShow] = useState(false);
	const [isActive, setIsActive] = useState('');

	const handleSearchChange = (e) => {
		// if (isEmpty(sessionStorage.getItem('ID Token'))) {
		// 	setMessage({ type: 'error', msg: 'Please login first to continue', place: 'globle' });
		// 	return;
		// }
		setSearchQuery(e.target.value);
	};

	const handleOptionSelect = (value, name) => {
		setIsActive(name);

		setSearchQuery(name);
		setProvider(value);
		setListShow(false);
	};

	useEffect(()=>{
		if(!isEmpty(controls?.billerId)) return;
         setSearchQuery("");
		 setProvider("");
		 setIsActive("");
	},[controls?.billerId])
	return (
		<CustomDropdownWrapper>
			<input type="text" placeholder="Search operator" value={searchQuery} onChange={handleSearchChange} onFocus={() => setListShow(true)} />
			{listShow && list.length > 0 && (
				<div className="dropdown">
					{type === 'bill' && (
						<>
							{list
								.filter((option) => option.billerName.toLowerCase().includes(searchQuery.toLowerCase()))
								.map((option, index) => (
									<div
										key={index}
										className={isActive === option.billerName ? 'dropdown-option active' : 'dropdown-option'}
										onClick={() => handleOptionSelect(option.billerId, option.billerName)}
									>
										<div className="icons">
											<img src={option.iconUrl} alt='operator-img' />
										</div>
										<div className="name">{option.billerName}</div>
									</div>
								))}
						</>
					)}
					{type === 'mobile' && (
						<>
							{list
								.filter((option) => option.operatorName.toLowerCase().includes(searchQuery.toLowerCase()))
								.map((option, index) => (
									<div
										key={index}
										className={isActive === option.operatorName ? 'dropdown-option active' : 'dropdown-option'}
										onClick={() => handleOptionSelect(`${option?.operatorId},${option?.operatorCode}`, option?.operatorName)}
									>
										<div className="icons">
											<img src={option.operatorImage} alt='oprtator-imf' />
										</div>
										<div className="name">{option.operatorName}</div>
									</div>
								))}
						</>
					)}
				</div>
			)}
		</CustomDropdownWrapper>
	);
};

export default CustomDropdown;
