import { useState, useEffect, useRef } from 'react';
import { checkQRPaymentStatus, fetchUtilityBillRequest, generateQR, getProviderDetailRequest, getProviderListRequest, payUtilityBillRequest } from '../../utils/api';
import { isEmpty } from '../../utils/common';
import OfferBanner from '../OfferBanner';
import { AlertWrapper,TableWrapper } from '../UI/styledConstants';
import { Wrapper } from './style';
// import { providerTypeOptions } from './constants';
import Loader from '../UI/Loader/Loader';
import ElectricityReceipt from './ElectricityReceipt';
import CustomDropdown from '../CustomDropdown/CustomDropdown';
import IconClose from '../../assets/images/IconClose';
import QrCode from '../QrCode/QrCode';

const initialControls = {
	providerType: 'FASTAG',
	account: '',
	billNumber: '',
	consumerMobileNumber: '',
	eroNumber: '',
	billerId: '',
	userId: '',
	amount: '',
	clientId: '',
	dateOfBirth: '',
	billPaymentRequest: '',
	emailId: '',
	mobile:'',
};

export default function Component({ className = '', BillType = '', billHeading = '' }) {
	const [operatorList, setOperatorList] = useState([]);
	const [billDetails, setBillDetails] = useState({});
	const [payDaital, setPayDaital] = useState('');
	const [controls, setControls] = useState({ ...initialControls });
	const [message, setMessage] = useState({});
	const [loading, setLoading] = useState(false);
	const [showGetBill, setShowGetBill] = useState(false);
	const [dynamicField, setDynamicField] = useState([]);
	const [dynamicFieldValues, setDynamicFieldValues] = useState({});
	const [heading, setHeading] = useState('');
	const [provider, setProvider] = useState('');
	const [activeMobilePop, setActiveMobilePop] = useState(false);
	const [qr, setQr] = useState('');
	const [timeOver, setTimeover] = useState(false);
	const [responce, setResponce] = useState({});
	const [statusResp, setStatusResp] = useState({});
	const [payAmount, setPayAmount] = useState(0);

	const numberReg = /^[0-9]+$/;
	const amountReg = /^[0-9.]+$/;
	const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;


	let timeout = null;

		
		


	useEffect(() => {
		setControls({
			...controls,
			providerType: BillType,
		});

		setHeading(billHeading);

		return clearTime();
	}, []);

	//for static field
	const onChangeHandler = async (e) => {
		// if (isEmpty(sessionStorage.getItem('ID Token'))) {
		// 	setMessage({ type: 'error', msg: 'Please login first to continue', place: 'globle' });
		// 	return;
		// }
		const { name, value } = e.target;
		if (name === 'consumerMobileNumber') {
			if (!numberReg.test(value) && value) {
				return false;
			}
		}
		if (name === 'amount') {
			if (!amountReg.test(value) && value) {
				return false;
			}
		}

		const _controls = {
			...controls,
			[name]: value,
		};

		// if (name === 'billerId') {
		// 	if (isEmpty(value)) {
		// 		return;
		// 	}

		// 	providerDaitals(value);
		// }

		setControls(_controls);
	};

	function providerDaitals(billerId) {
		setBillDetails({});
		setLoading(true);
		getProviderDetailRequest(billerId).then((resp) => {
			setLoading(false);
			if (resp?.success) {
				if (resp?.data?.data?.parameters.length > 0) {
					const _dynamicField = resp?.data?.data?.parameters;
					setDynamicField([..._dynamicField]);
				}
				if (resp?.data?.data?.fetchRequirement === 'MANDATORY') {
					setShowGetBill(true);
				} else {
					setShowGetBill(false);
				}
			} else {
				setShowGetBill(false);
				if (resp?.errorCodeList.length > 0) {
					setMessage({ type: 'error', msg: resp?.errorCodeList[0], place: 'globle' });
				} else {
					setMessage({ type: 'error', msg: resp?.msg, place: 'globle' });
				}
			}
		});
	}

	const fetchUtilityBill = () => {
		clearTime();
		if (controls.providerType === '') {
			setMessage({ type: 'error', msg: 'Please select provider type', place: 'form' });
			return;
		} else if (controls.billerId === '') {
			setMessage({ type: 'error', msg: 'Please select operator', place: 'form' });
			return;
		} else if (BillType === 'INSURANCE' && !emailReg.test(controls.emailId)) {
			setMessage({ type: 'error', msg: 'Please enter valid email', place: 'form' });
			return;
		}

		var returnValue = dynamicField.map((val) => {
			if (val.desc === 'Amount') {
				setDynamicFieldValues({
					...dynamicFieldValues,
					[val.name]: 0,
				});
			} else {
				if (!dynamicFieldValues[val.name]) {
					setMessage({ type: 'error', msg: 'Please enter' + val.desc, place: 'form' });
					return false;
				} else if (val?.regex !== '') {
					var regex = new RegExp(val?.regex);
					let text = dynamicFieldValues[val.name];
					if (!regex.test(text)) {
						setMessage({ type: 'error', msg: 'Please enter currect' + val.desc, place: 'form' });
						return false;
					}
				}
			}
		});

		if (returnValue.includes(false)) {
			return;
		}
		setLoading(true);

		const params = {
			amount: 0,
			inputParameters: dynamicFieldValues,
			billerId: controls.billerId,
			consumerPan: '',
			enquiryReferenceId: '',
			externalRef: '',
			geoCode: '28.6326,77.2175',
			merchantId: '',
			paymentMode: '',
			postalCode: '',
			remarks: '',
			serviceName: '',
			userId: '',
		};

		if (BillType === 'INSURANCE') {
			params.emailId = controls.emailId;
		}

		fetchUtilityBillRequest(params).then((response) => {
			setLoading(false);
			if (response?.success) {
				setBillDetails(response?.data);
				setPayAmount(response?.data.billAmount);
				setActiveMobilePop(true);
			} else {
				if (response?.errorCodeList.length > 0) {
					setMessage({ type: 'error', msg: response?.errorCodeList[0], place: 'globle' });
				} else {
					setMessage({ type: 'error', msg: response?.msg, place: 'globle' });
				}
			}
		});
	};

	const payUtilityBill = (amount="") => {
		clearTime();
		if (controls.providerType === '') {
			setMessage({ type: 'error', msg: 'Please select provider type', place: 'form' });
			return;
		} else if (controls.billerId === '') {
			setMessage({ type: 'error', msg: 'Please select operator', place: 'form' });
			return;
		} else if (BillType === 'INSURANCE' && !emailReg.test(controls.emailId)) {
			setMessage({ type: 'error', msg: 'Please enter valid email', place: 'form' });
			return;
		} else if (amount < 100) {
			setMessage({ type: 'error', msg: 'Please enter  minimum 100 rupee', place: 'form' });
			return;
		}

		var returnValue = dynamicField.map((val) => {
			if (val.desc === 'Amount') {
				setDynamicFieldValues({
					...dynamicFieldValues,
					[val.name]: amount,
				});
			} else {
				if (!dynamicFieldValues[val.name]) {
					setMessage({ type: 'error', msg: 'Please enter' + val.desc, place: 'form' });
					return false;
				} else if (val?.regex !== '') {
					//let  myRegExp = "/"+val?.regex+"/";
					var regex = new RegExp(val?.regex);
					let text = dynamicFieldValues[val.name];
					if (!regex.test(text)) {
						setMessage({ type: 'error', msg: 'Please enter currect' + val.desc, place: 'form' });
						return false;
					}
				}
			}
		});
		if (returnValue.includes(false)) {
			return;
		}
		let geoCode= "28.6326,77.2175";
		// if(!isEmpty(geolocation?.latitude)){
		// 	geoCode = `${(geolocation?.latitude)?.toFixed(4)},${(geolocation?.longitude)?.toFixed(4)}`;
		// }
		setLoading(true);
		let amount_ =  payAmount.split(".")

		console.log("amount_",amount_)
	
		const params = {
			amount: amount_[0],
			inputParameters: dynamicFieldValues,
			billerId: controls.billerId,
			consumerPan: '',
			enquiryReferenceId: billDetails?.enquiryReferenceId,
			externalRef: '',
			geoCode: geoCode,
			merchantId: '',
			paymentMode: "",
			postalCode: '',
			remarks: '',
			serviceName: BillType,
			userId: '',
		};

		if (BillType === 'INSURANCE') {
			params.emailId = controls.emailId;
		}
	
	
		generateQR(params).then((resp) => {
			setLoading(false);
			setControls({
				...initialControls,
			});
			setDynamicField([]);
		
	
			setLoading(false);
					if (resp?.success) {
						if(resp?.data?.data?.status === "FAIL"){
							setMessage({ type: 'error', msg: 'Transaction Fail', place: 'globle' });
						}else{
							setQr(resp?.data?.code);
							setResponce(resp?.data);
							setActiveMobilePop(true);
	
							checkstatus(resp?.data?.orderId);
						}
						
					} else {
						setMessage({ type: 'error', msg: 'Please enter amount', place: 'local' });
						setQr('');
						setActiveMobilePop(false);
					}
		});
	};
	const onCloseModal = () => {
		setStatusResp({});
		setControls({ ...initialControls });
		setDynamicField([]);
		setDynamicFieldValues({});
		setShowGetBill(false);
	};

	useEffect(() => {
		if (!isEmpty(message)) {
			setTimeout(() => {
				setMessage({});
			}, 5000);
		}
	}, [message]);

	const onChangeRequiredField = (e) => {
		setDynamicFieldValues({
			...dynamicFieldValues,
			[e.target.name]: e.target.value,
		});
	};

	useEffect(() => {
		async function getProviderList() {
			// if (isEmpty(sessionStorage.getItem('ID Token'))) {
			// 	//setMessage({ type: 'error', msg: 'Please login first to continue',place:"globle" });
			// 	return;
			// }
			if (!isEmpty(controls.providerType)) {
				setLoading(true);
				const response = await getProviderListRequest({ providerType: controls.providerType });
				setLoading(false);
				if (response?.success) {
					setOperatorList(response?.data);
				}
			}
		}
		getProviderList();
	}, [controls.providerType, sessionStorage.getItem('ID Token')]);

	useEffect(() => {
		if (!isEmpty(provider)) {
			const _controls = {
				...controls,
				billerId: provider,
			};
			setControls(_controls);

			providerDaitals(provider);
		}
		clearTime();
		
	}, [provider]);
	const handleFormPlan = (data) => {
		setActiveMobilePop(false);
		setControls({ ...controls, amount: data });
	};
	const clearTime = () => {
		
			clearTimeout(timeout);
		
	};

	useEffect(() => {
		if (timeOver) {
			setMessage({ type: 'error', msg: 'Timeoute', place: 'globle' });
			setQr('');
			setTimeover(false);
			clearTime();
			setActiveMobilePop(false);
		}
	}, [timeOver]);


	const checkstatus = (order) => {
	
		 timeout = setTimeout(() => {
			checkQRPaymentStatus(order).then((resp) => {
				if (resp?.success) {
					if (resp?.metaInfo.status === 'PENDING') {
						
						checkstatus(order);

					} else if (resp?.metaInfo?.status === 'SUCCESS') {
						setMessage({ type: 'success', msg: 'Payment Success', place: 'globle' });
						// setRefresh(!refresh);

						setStatusResp({...resp?.metaInfo});
						  console.log("closePop======",resp?.data)
						// closePop();
						setQr("");
						clearTime();
					} else {
						setMessage({ type: 'error', msg: 'Payment Fail', place: 'globle' });
						setStatusResp({...resp?.metaInfo});
						setQr("");
						// closePop();
						clearTime();
					}
				} else {

					if(resp?.errorCodeList?.length > 0){
						setMessage({ type: 'error', msg: resp?.errorCodeList, place: 'globle' })
						return;
					}
					setMessage({ type: 'error', msg: resp?.msg, place: 'globle' })
				}
			});
		}, 3000);
	
	};
	
	return (
		<Wrapper className={`tab-content-wrapper ${className}`}>
			{!isEmpty(message) && message.place == 'globle' && (
				<AlertWrapper className={`${message.type} globle`}>
					<span className="close" onClick={() => setMessage({})}>
						×
					</span>
					{message.msg}
				</AlertWrapper>
			)}
			<div className="tab-content-left">
				<h2 style={{color:"#fff"}}>{heading} Bill</h2>
			<div className='flex gap-20'>
			<div className="form-field " style={{display:"none"}}>
					<select className="item-select mobile-operator" defaultValue={controls?.providerType} placeholder="Operator" name="providerType">
						<option value={controls?.providerType}>{heading}</option>
					</select>
				</div>

				<div className="form-field">
					<CustomDropdown list={operatorList} setProvider={setProvider} type="bill" controls={controls} setMessage={setMessage} />
				</div>
				{dynamicField &&
					dynamicField.map((val) => {
						if (!(val.desc === 'Amount')) {
							return (
								<div className="form-field">
									<input
										type="text"
										name={val?.name}
										placeholder={val?.desc}
										maxLength={val?.maxLength}
										minLength={val?.minLength}
										onChange={(e) => onChangeRequiredField(e)}
										required
									/>
								</div>
							);
						}
					})}

				{BillType === 'INSURANCE' && (
					<div className="form-field">
						<input type="text" name="emailId" placeholder="Email" value={controls?.emailId} onChange={onChangeHandler} />
					</div>
				)}
				{/* <div className="form-field">
					<input type="text" name="amount" placeholder="Amount" value={controls?.amount} onChange={onChangeHandler} />
				</div> */}
				<div className="form-field">
					<input type="text" name="mobile" placeholder="Mobile" value={controls?.mobile} onChange={onChangeHandler} />
				</div>
				{ (
					<button type="submit" className="button-outline" onClick={fetchUtilityBill}>Fetch Bill</button>
					
				)}
				{/* {!isEmpty(billDetails) &&<button type="submit" className="button-outline" onClick={() => payUtilityBill()}>
					Continue
				</button>} */}
			</div>
				
				{!isEmpty(message) && message.place == 'form' && (
					<AlertWrapper className={message.type}>
						<span className="close" onClick={() => setMessage({})}>
							×
						</span>
						{message.msg}
					</AlertWrapper>
				)}
				
				{/* <button type="submit" className="button-outline" onClick={() => fetchUtilityBill()}>
					Continue
				</button> */}
			</div>
			<div className={`tab-content-right ${!activeMobilePop ? '' : 'active'}`}>
				<div
					className="d-m-block close-pop"
					onClick={() => {
						setActiveMobilePop(false);
					}}
				>
					<IconClose />
				</div>
				{!isEmpty(billDetails) &&  isEmpty(qr) && (
					<>
						<h2>Bill Details</h2>
						<TableWrapper>
							<table className="table">
								<thead>
									<tr>
										<th>Biller Name</th>
										<th>Biller Number</th>
										<th>Bill Date</th>
										<th>Bill Period</th>
										<th>Due Date</th>
										<th>Due Amount</th>
										<th>Add Balence</th>
									</tr>
								</thead>
								<tbody>
									{!isEmpty(billDetails) && (
										<tr>
											<td className="bold">{billDetails?.customerName}</td>
											<td>{billDetails?.billNumber}</td>
											<td>{billDetails?.billDate}</td>
											<td>{billDetails?.billPeriod}</td>
											<td>{billDetails?.billDueDate}</td>
											<td className='flex' style={{alignItems:"center",gap:"8px"}}> <span>&#8377;</span>
											<div className="form-field" title={billDetails.billAmount}>
						<input type="number" name="emailId" className='input'  value={payAmount} onChange={(e) =>setPayAmount(e.target.value)} />
					</div>
												 </td>
											<td>
												<div className="payee-actions flex items-center">
													<button className="button-outline mr20" onClick={(e) => payUtilityBill(payAmount)}>
														Pay Bill
													</button>
													{/* <button className="button-outline mr20" onClick={() =>payUtilityBill(billDetails?.billAmount) }>
														Pay
													</button> */}
												</div>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						
						</TableWrapper>
					</>
				)}
				{isEmpty(billDetails) && !loading &&  isEmpty(qr) && <OfferBanner />}
				{!isEmpty(qr) && !loading && (
						<>
							<QrCode qrcode={qr} setTimeover={setTimeover} resp={responce} />
						</>
					)}
			</div>

			 {!isEmpty(statusResp) && <ElectricityReceipt payDaital={statusResp} onCloseModal={onCloseModal} />} 
			{loading && <Loader />}
{/* <form  style={{display:"none"}} name="my_form" action="https://payment.prokart.co.in/initPayment" method="post">
 <input class="form-control" type="text" id="name" name="clientId" value="Nexa_live_ROlEfIkRuKDaBJEF" />
 <input name="returnUrl" value="https://www.test.com/return" />
 <input type="text" name="encrypt" value={payDaital} />
 <button type="submit">Submit</button>
</form> */}
		</Wrapper>
	);
}
